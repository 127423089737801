import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 60px 32px 0;
    margin: 0;
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.colors.secondary};
    text-align: center;
    font-family: Oxanium;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    text-transform: none;
`;

export const Description = styled.p`
    color: ${(props) => props.theme.colors.black};
    text-align: center;
    font-family: Oxanium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    margin: 30px 0;

    b {
        font-weight: 700;
    }
`;

export const MobileNumberViewer = styled.input`
    width: 100%;
    border-radius: 20px;
    text-align: center;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.textSubtle};
    background-color: #e3e3e3;
    margin-bottom: 15px;
    border: none;
    height: 40px;
`;

export const LoaderContainer = styled.div`
    padding-top: 10px;
    width: 100%;
    height: 111px;
`;

