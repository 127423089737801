import { EmailIcon } from "../../assets/img/EmailIcon";
import { InterrogationIcon } from "../../assets/img/InterrogationIcon";
import { MessageIcon } from "../../assets/img/MessageIcon";
import { SmsIcon } from "../../assets/img/SmsIcon";
import { WhatsAppIcon } from "../../assets/img/WhatsappIcon";
import setaDirVerdefrom from "../../assets/img/seta-dir-verde.svg";
import pdfSMS from "../../assets/pdf/tutorial_desbloqueio_SMS_2024_pay.pdf";
import pdfWhatsAPP from "../../assets/pdf/tutorial_desbloqueio_Whatsapp_2024_pay.pdf";
import Collapse from "../Collapse";
import Channel from "./Channel";
import { NeedHelp, ResendCodeContainer, ResendFields } from "./styles";

interface IResendCode {
    onResend: (channel_name: string) => void;
    sentTo?: "SMS" | "WhatsApp" | "E-mail";
    user: {
        mobile_number?: string;
        email?: string;
    };
    useEmail?: boolean;
    useSMS?: boolean;
    useWhatsApp?: boolean;
    theme?: "primary" | "secondary";
}

export const ResendCode = ({
    user: { mobile_number, email },
    theme = "primary",
    sentTo = "WhatsApp",
    useEmail,
    useSMS,
    useWhatsApp,
    onResend,
}: IResendCode) => {
    function handleResendCode(channelName: string) {
        if (onResend) onResend(channelName);
    }

    const authType = localStorage.getItem("authType") || null;
    const authIcon = authType === "WhatsApp" ? <WhatsAppIcon /> : <SmsIcon />;
    const resendCode = authType === "WhatsApp" ? "whatsapp" : "sms";
    const tutorialPDF = authType === "WhatsApp" ? pdfWhatsAPP : pdfSMS;
    

    return (
        authType === "WhatsApp" || authType === "SMS" ? (
            <ResendCodeContainer>
                <Collapse
                    title={"Clique aqui"}
                    description={`(Se não receber seu código via ${authType})`}
                    icon={<InterrogationIcon />}
                >
                    
                <ResendFields $theme={theme}>
                        <a
                            href={tutorialPDF }
                            target="_blank"
                            className="container-text"
                            rel="noreferrer"
                        >
                            <div className="tutorial">
                                <div>
                                    {authIcon}
                                    <span>
                                        <p>Entenda como liberar o bloqueio</p>
                                        <p>
                                            <strong>DO {authType} NO SEU CELULAR</strong>
                                        </p>
                                    </span>
                                </div>
                                <img src={setaDirVerdefrom} alt="" />
                            </div>
                        </a>
                    {mobile_number ? (
                        <Channel
                            icon={authIcon}
                            text={`Receber por ${authType}`}
                            value={mobile_number}
                            onSend={() => handleResendCode(resendCode)}
                        />
                    ) : null}

                    {email && useEmail ? (
                        <Channel
                            icon={<EmailIcon />}
                            text="Receber por E-mail"
                            value={email}
                            onSend={() => handleResendCode("email")}
                        />
                    ) : null}

                    <fieldset />

                    <NeedHelp href="/faq">
                        <MessageIcon />
                        <span>PRECISA DE AJUDA ?</span>
                    </NeedHelp>
                </ResendFields>
            </Collapse>
           
            </ResendCodeContainer>
        ) : null
    );
};
