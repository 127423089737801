import { useState, RefObject, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/x-date-pickers";
import { ptBR as coreptBr } from "@mui/material/locale";
import ptBRLocale from "date-fns/locale/pt-BR";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

export interface DatePickerProps {
    onChange: (data: string) => void;
    initialValue: Date | null;
    clear?: RefObject<HTMLButtonElement>;
    label?: string;
    className?: string;
}

export function DateTime(props: DatePickerProps) {
    const { onChange, initialValue, label, className} = props;

    const [value, setValue] = useState<Date | null>(null);

    const theme = createTheme(
        {
            palette: {
                primary: { main: "#5DB356" },
            },
        },
        ptBR, // x-date-pickers translations
        coreptBr // core translations
    );

    useEffect(() => {
        if (initialValue) {
            setValue(new Date(initialValue));
        }
    }, [initialValue]);

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
            >
                <MobileDateTimePicker
                    toolbarTitle="SELECIONE DATA E HORA"
                    defaultCalendarMonth={new Date()}
                    ampm={false}
                    value={value}
                    onOpen={() => {
                        const date = new Date();
                        setValue(date);
                        onChange(date.toLocaleString().replace(/,/g, ""));
                    }}
                    onChange={(newValue) => {
                        const date = newValue
                            ? newValue.toLocaleString().replace(/,/g, "")
                            : "";

                        onChange(date);

                        if (newValue) {
                            setValue(newValue);
                        }
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box
                            className={`${
                                className
                                    ? className
                                    : "container-input-system calendario"
                            }`}
                        >
                            <input
                                ref={inputRef}
                                {...inputProps}
                                placeholder={label}
                            />
                            {InputProps?.endAdornment}
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
