import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";
import TableRegulamento from "../../../../Table";
import imgEditar from "../../../../../../assets/img/editar.svg";
// import imgX from "../../../../../../assets/img/x-vermelho.svg";
import { TableStyles } from "./styles";

export function Table() {
    const { campanhaById } = useCampanha();

    console.log(campanhaById.nome);

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 100,
            hide: true,
        },
        {
            field: "nome",
            headerName: "Nome",
            width: 220,
        },
        {
            field: "regulamento",
            headerName: "Arquivo",
            width: 220,
            renderCell: ({ row }: GridRenderCellParams) => {
                console.log(row.regulamento);
                if (!row.regulamento) return <></>;
                return (
                    <a href={row.regulamento} target="_blank" rel="noreferrer">
                        <img src={imgEditar} alt="" />
                    </a>
                );
            },
            hideSortIcons: true,
            disableColumnMenu: true,
        },
        // {
        //     field: "excluir",
        //     headerName: "EXCLUIR",
        //     width: 220,
        //     renderCell: ({ row }: GridRenderCellParams) => {
        //         return (
        //             <button>
        //                 <img src={imgX} alt="" />
        //             </button>
        //         );
        //     },
        //     hideSortIcons: true,
        //     disableColumnMenu: true,
        // },
    ];

    const data = [
        {
            id: 1,
            nome: campanhaById.nome,
            data_cadastro: "Falta data",
            regulamento: campanhaById.regulamento,
            // excluir: "",
        },
    ];

    return (
        <TableStyles>
            <TableRegulamento
                rows={data}
                columns={collumns}
                nameCSV="REGULAMENTO"
            />
        </TableStyles>
    );
}
