export const SmsIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="5.1630001mm" height="5.1630001mm" viewBox="0 0 5.1630001 5.1630001" version="1.1" id="svg5">
  <defs id="defs2"/>
  <g id="layer1">
    <path fill-rule="evenodd" clip-rule="evenodd" d="m 3.110417,4.43333 h 1.322917 c 0.292364,0 0.529166,-0.2368 0.529166,-0.52916 v -3.175 C 4.9625,0.4368 4.725698,0.2 4.433334,0.2 H 0.729167 C 0.436802,0.2 0.2,0.4368 0.2,0.72917 v 3.175 c 0,0.29236 0.236802,0.52916 0.529167,0.52916 H 2.052084 L 2.58125,4.9625 Z" stroke="#57b847" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" id="path132" style={{ fill: 'none', strokeWidth: 0.4, strokeDasharray: 'none' }}/>
    <path d="m 2.628082,2.26986 c 0.02593,0.0259 0.02593,0.0677 0,0.0937 -0.02593,0.0259 -0.06773,0.0259 -0.09366,0 -0.02593,-0.0259 -0.02593,-0.0677 0,-0.0937 0.02593,-0.0257 0.06773,-0.0257 0.09366,0" stroke="#57b847" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" id="path134" style={{ fill: '#00ff00', fillOpacity: 1, strokeWidth: 0.5, strokeDasharray: 'none' }}/>
    <path d="m 3.686415,2.26986 c 0.02593,0.0259 0.02593,0.0677 0,0.0937 -0.02593,0.0259 -0.06773,0.0259 -0.09366,0 -0.02593,-0.0259 -0.02593,-0.0677 0,-0.0937 0.02593,-0.0257 0.06773,-0.0257 0.09366,0" stroke="#57b847" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" id="path136" style={{ fill: '#00ff00', fillOpacity: 1, strokeWidth: 0.5, strokeDasharray: 'none' }}/>
    <path d="m 1.569748,2.26986 c 0.02593,0.0259 0.02593,0.0677 0,0.0937 -0.02593,0.0259 -0.06773,0.0259 -0.09366,0 -0.02593,-0.0259 -0.02593,-0.0677 0,-0.0937 0.02593,-0.0257 0.06773,-0.0257 0.09366,0" stroke="#57b847" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" id="path138" style={{ fill: '#00ff00', fillOpacity: 1, strokeWidth: 0.5, strokeDasharray: 'none' }}/>
  </g>
</svg>
  );
};


