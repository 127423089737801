import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .container-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        opacity: 0;
        pointer-events: none;
        &.active {
            background: rgba(255, 255, 255, 0.5);
            opacity: 1;
            transition: all 0.3s ease-in-out;
            pointer-events: all;
        }
    }
`;

interface CardProps {
    background: string;
}

export const CardStyles = styled.div<CardProps>`
    background: ${(props) => props.background};
    border-radius: 8px;
    padding: 4px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-img {
        margin-bottom: auto;
        img {
            width: 16px;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.white};
        &:first-child {
            margin-bottom: 4px;
        }
    }

    .saldo {
        border: 0.658986px solid ${(props) => props.theme.colors.white};
        height: 35px;
        border-radius: 2.63594px;
    }
`;

export const Input = styled.div`
    input[type="number"] {
        -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input {
        border: 1px solid transparent !important;
        background-color: ${(props) => props.theme.colors.white} !important;
        border: 1px solid #e1e2e3 !important;
        width: 50px;
        height: 30px;
        padding: 0px 8px;
        text-align:center;
    }
`;

export const TaxaStyles = styled.div`
    input {
        border: none;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        padding: 0 5px;
        width: 100%;
        overflow: hidden;
        border: 1px solid #e1e2e3;
        height: 28px;
        padding-left: 10px;
        padding-right: 10px;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }
`;
