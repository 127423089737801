import imgManutence from "../../../../src/assets/img/slide/manutencao.png";
import imgLogo from "../../../../src/assets/img/logo-sistema.svg";
import retorno from "../../../../src/assets/img/slide/retorno.svg";
import { useManutence } from "../../../Hook/system/useManutence";
import { Render } from "./styles";
type PreviewContentProps = {
    mobile?:boolean
}

export function ManutenceScreen({mobile=false}:PreviewContentProps) {
        const { 
            data, 
        } = useManutence();
    
    
    const formatedEndData = new Date(data.endDate)
    .toLocaleDateString("pt-BR", { hour: "2-digit", minute: "2-digit" })
    .replace(", ", " - ");

    return (
        <Render>
            <div className={`preview-content ${mobile ? "mobile-content" : ""}`}>
                <img className="imagem" src={imgManutence} alt="" />
                <div className="description">
                    <img className="logo" src={imgLogo} alt="" />
                    <h2>{ data.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: data.warning }} ></p>
                    <div>
                        <img src={retorno} alt="" />
                        <span>
                            <h4>Previsão de retorno:</h4>
                            <p>{ data.endDate && formatedEndData}</p>
                        </span>
                    </div>
                </div>
            </div>
        </Render>
    );
}
