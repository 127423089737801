import styled from "styled-components";
import { Container as DefaultHeader } from "../Header/styles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;

    main {
        display: flex;
        .control {
            flex: 1;
            max-width: 380px;
            padding-top: 20px;
            padding-right: 35px;
            border-right: 1px solid #e1e2e3;

            @media (min-width: 2000px) {
                max-width: 486px;
                padding-right: 61px;
            }
            color: ${({ theme }) => theme.colors.primaryBright};
            .active {
                position: relative;
                display: flex;
                justify-content: space-between;
                height: 75px;
                padding-top: 15px;
                border-bottom: 1px solid #e1e2e3;

                p {
                    height: 22px;
                    font-size: 12px;
                    color: ${({ theme }) => theme.colors.primary};
                    font-weight: 600;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    line-height: 2.3;
                }

                .MuiSwitch-track {
                    position: absolute;
                }
            }

            .setup {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-top: 83px;

                &.disabled{
                    pointer-events: none;
                }

                .date {
                    width: 100%;
                    input {
                        font-family: Oxanium;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: 8%;
                        color: ${({ theme }) => theme.colors.primary};

                        &::placeholder {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 12px;
                            letter-spacing: 8%;
                            color: ${({ theme }) => theme.colors.primaryDark};
                            text-transform: uppercase;
                        }
                    }
                }

                .custom {
                    display: flex;
                    justify-content: space-between;

                    span {
                        display: flex;
                        align-items: center;

                        p {
                            margin-top: 5px;
                            font-size: 12px;
                            color: ${({ theme }) => theme.colors.primaryDark};
                            font-weight: 600;
                            letter-spacing: 0.1em;
                            text-transform: uppercase;
                        }

                        .radio {
                            padding: 0;
                            margin-right: 8px;

                            &.Mui-checked {
                                color: ${({ theme }) => theme.colors.secondary};
                            }
                        }
                    }
                }

                .message {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    gap: 12px;
                    

                    img {
                        position: absolute;
                        top: 23px;
                        left: 15px;
                        transform: translateY(-50%);
                        color: ${({ theme }) => theme.colors.secondary};
                    }

                    .title{
                        width: 100%;
                        border: 1px solid #c6c6c6;
                        border-radius: 4px;
                        background-color: #efefef;
                        padding: 15px 18px 15px 50px;

                        font-family: Oxanium;
                        font-size: 12px;
                        color: ${({ theme }) => theme.colors.primary};
                        font-weight: 500;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;

                        &::placeholder {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 12px;
                            letter-spacing: 8%;
                            color: ${({ theme }) => theme.colors.primary};
                            text-transform: uppercase;
                        }
                    }
                        
                    .defaultLayer{
                        position: absolute;
                        width: 100%;
                        height: 256px;
                        background-color: tranparent;
                    }


                    button {
                        width: 220px;
                        margin-top: 12px;

                        font-weight: 600;
                    }
                }
            }
        }

        .preview {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding-top: 20px;

            .subTitle {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 8px;
                padding: 15px 0 35px 97px;
                border-bottom: 1px solid #e1e2e3;

                @media (min-width: 2000px) {
                    justify-content: center;
                }

                p {
                    font-size: 12px;
                    color: ${({ theme }) => theme.colors.secondary};
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0.1em;
                }
            }

            .screens {
                display: flex;
                flex-direction: column;
                padding: 25px 97px 0 97px;
                justify-content: center;
                align-items: center;
                gap: 97px;

                transition : filter 500ms linear;

                &.disabled{
                    filter: grayscale(1);
                }
                
                @media (min-width: 2000px) {
                    flex-direction: row;
                    padding: 25px 0 0 0;
                }

                .desktop,
                .mobile {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    gap: 30px;
                    align-items: center;

                    width: 100%;
                }

                .desktop {
                    max-width: 749px;
                }

                .mobile {
                    max-width: 245px;
                }
            }
        }
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 77px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 4px;

    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    letter-spacing: 0.1em;
    text-transform: uppercase;
`;

export const HeaderStyles = styled.div`
    display: flex;
    border-bottom: 1px solid #e1e2e3;
    justify-content: space-between;
    ${DefaultHeader} {
        border-bottom: unset;
    }
`;
