import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 27px 44px;

    width: 100%;

    text-align: center;

    h5 {
        color: ${(props) => props.theme.colors.secondary};
        font-weight: 600;
        margin: 20px 0 30px;

        strong {
            color: ${(props) => props.theme.colors.warning};
        }
    }

    button#submit {
        background: ${({ theme }) => theme.colors.secondary};
        text-transform: none;
        font-weight: 600;
        height: 40px;
    }

    img {
        width: 48px;
    }

    .cancelar {
        margin: 0 auto;
        display: block;
        text-transform: uppercase;
        margin-top: 12px;
    }
`;

export const Title = styled.h2`
    font-size: 20px;
    font-weight: 700;
    font-family: Oxanium;
    padding-top: 35px;
    color: ${({ theme }) => theme.colors.secondary};
`;
