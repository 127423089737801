import { Header as DefaultHeader } from "../Header";
import { HeaderStyles } from "./styles";

export function Header() {

    return (
        <HeaderStyles>
            <DefaultHeader
                title="MANUTENÇÂO"
                titleBtn=""
                permission="faq.cupom"
            />
           
        </HeaderStyles >
    );
}
