import styled from "styled-components";
import { Swiper } from "swiper/react";

export const SwiperSlideWrap = styled(Swiper)`
    &.swiper-slide-login {
        width: 690px;
        margin: 0px;
        margin-left: calc(100% - 690px);
        /* margin-left: calc((100% - 690px) / 2); */
        /* margin-right: calc((100% - 690px) / 2); */
        transition: all 0.5s;
    }

    @media (max-width: 768px) {
        &.swiper-slide-login {
            .swiper-slide {
                img {
                    /* margin-left: -1vw !important; */
                }
            }
        }
    }
`;

export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.secondary};
    min-height: calc(100vh - 81px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    overflow: hidden;

    .text-aviso {
        width: 348px;
    }

    &.active {
        .content-img-text {
            grid-gap: 0px;
            align-items: stretch;
            .container-img {
                display: block;
                transition: all 0.5s;
                ${SwiperSlideWrap} {
                    width: 70%;
                    margin-left: calc((100% - 690px) / 2);
                    margin-right: calc((100% - 690px) / 2);
                    transition: all 0.5s;
                }
            }
            .content-form {
                margin: 0px !important;
                padding-top: 0;
                transition: all 0.5s;
                overflow: hidden;
                form {
                    width: 300px;
                }
                &:before {
                    bottom: 0px;
                    background-color: ${(props) => props.theme.colors.white};
                    transition: bottom 0.8s;
                }
                & > div {
                    background-color: transparent;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;
                    padding-left: 144px;
                }
            }
        }
    }

    .content-img-text {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 200px;
        align-items: start;
        justify-content: center;
        width: 100%;
        .container-img {
            ${SwiperSlideWrap} {
                img {
                    /* width: 100%; */
                }
            }
            img {
                &.logo-mobile {
                    display: none;
                }
            }
            .container-texto-boas-vindas {
                display: none;
            }
        }
    }
    .container-img {
        /* padding-top: 12vw; */
        margin-right: auto;
        transition: all 0.5s;
        width: 100%;
        ${SwiperSlideWrap} {
            max-width: 690px;
            margin-left: calc(100% - 690px);
            /* margin-left: 100%; */
            display: block;
            transition: all 0.5s;
        }

        .container-img-manutencao {
            margin-left: auto;
            margin-right: -5%;
            width: fit-content;
        }
    }

    .content-form {
        /* margin-top: 10%; */
        /* padding-top: 150px; */
        position: relative;
        &.manutencao {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            margin-left: -5%;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            bottom: -100%;
            background-color: transparent;
            transition: bottom 0.8s;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }

        & > div {
            display: flex;
        }

        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 40.5625px;
            line-height: 50px;
            color: ${(props) => props.theme.colors.white};
            margin-bottom: 36px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
            color: ${(props) => props.theme.colors.white};
            margin-bottom: 26px;
        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 26.495px;
            line-height: 33px;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.white};
            margin-bottom: 23px;
        }

        img {
            &.logo {
                width: 197px;
            }
        }

        .retorno {
            background-color: ${(props) => props.theme.colors.white};
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 32px 12px 23px;
            margin-bottom: 50px;
            .container-text {
                margin-left: 21px;
                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16.4368px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.secondary};
                    margin: 0px !important;
                }

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16.4368px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.primaryDark};
                }
            }
        }
    }

    @media (max-width: 1536px) {
        .content-img-text {
            grid-gap: 5rem;
            .container-img {
                ${SwiperSlideWrap} {
                    img {
                        width: 100%;
                    }
                }
            }
        }
        &.active {
            .content-img-text {
                grid-gap: unset;
                .container-img {
                    ${SwiperSlideWrap} {
                        margin-left: calc((100% - 75%) / 2);
                        margin-right: calc((100% - 75%) / 2);
                    }
                }
                .content-form {
                }
            }
        }
        .content-img-text {
            .container-img {
                /* padding-top: 8.5vw; */
                ${SwiperSlideWrap} {
                    width: 75%;
                    margin-left: calc(100% - 75%);
                }
            }

            .content-form {
                margin-top: 0px;
                margin-left: 0px;
            }
        }
    }

    @media (max-width: 1024px) {
        &.active {
            .content-img-text {
                .content-form {
                    & > div {
                        padding-left: 0px;
                        & > div {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        .content-img-text {
            flex-direction: column;
            height: auto;
            min-height: 100vh;
            position: relative;
            .container-img {
                width: 100%;
                text-align: center;
                margin-bottom: 14px;
                img {
                    &.logo-mobile {
                        display: block;
                        position: absolute;
                        top: 24px;
                        left: 24px;
                    }
                }

                ${SwiperSlideWrap} {
                    margin-left: -10vw;
                    width: 100%;
                    img {
                        margin-left: -10vw;
                    }
                }

                .container-img-manutencao {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    width: 70%;
                    padding-top: 64px;
                    img {
                        width: 100%;
                    }
                }
            }

            .content-form {
                &.manutencao {
                    text-align: center;
                    align-items: center;
                    margin-bottom: 0px;
                    h3 {
                        margin-bottom: 16px;
                    }
                    img {
                        &.logo {
                            display: none;
                        }
                    }

                    .retorno {
                        margin-bottom: 46px;
                        text-align: left;
                    }
                }
                .text-passo {
                    font-family: "Oxanium";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    /* identical to box height, or 14px */
                    width: 300px;
                    margin: 0 auto;
                    text-align: left;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;

                    color: ${(props) => props.theme.colors.black};
                }
            }
        }
        .container {
            .text-login {
                img {
                    display: none;
                }
            }
        }

        .content-form {
            width: 100%;
        }
    }
    //Que bom ver você
    @media (max-width: 768px) {
        padding: 0px;
        &.active {
            .content-img-text {
                .content-form {
                    padding-top: 45px;
                    margin-top: 49px !important;
                    &:before {
                        transition: bottom 0.3s;
                    }
                    form {
                        width: 300px;
                        padding-top: 24px;
                    }
                }

                .container-img {
                    .container-texto-boas-vindas {
                        .text-login-mobile {
                            opacity: 0;
                            position: static;
                        }

                        .text-registro {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        //container-tabs
        .content-img-text {
            display: flex;
            grid-gap: unset;
            padding-top: 0px;
            min-height: 100vh;
            justify-content: start;
            .container-img {
                padding-top: 64px;
                ${SwiperSlideWrap} {
                    margin-left: auto;
                    margin-right: auto;
                }

                .container-texto-boas-vindas {
                    display: block;
                    position: relative;
                    width: 280px;
                    margin: 0px auto;
                    margin-top: 20px;
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        text-align: center;
                        color: ${(props) => props.theme.colors.white};
                        opacity: 0;
                    }
                    .text-login-mobile {
                        opacity: 1;
                        position: absolute;
                    }
                }
            }
            .content-form {
                margin-right: auto;
                margin-top: 0px;
                margin-bottom: 56px;
                padding-top: 0px;
                height: auto;
                form {
                    width: 300px;
                }
                .container-tabs {
                    padding-top: 0px;
                }
            }
        }

        .container-img {
            margin-bottom: 0px;
        }
    }

    @media (max-width: 480px) {
        .text-aviso {
            width: 100%;
        }
    }
`;

export const AjduaStyles = styled.div`
    width: 400px;
    display: block !important;
    padding-bottom: 32px;
    .content-ajuda {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
    .container-video {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        position: relative;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #ffffff;
        }
        img {
            display: block;
            margin-bottom: 14px;
        }
    }

    .container-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        img {
            display: block;
            margin-top: 12px;
            margin-bottom: 14px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #ffffff;
        }
    }

    button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #f3faff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 29px;
        img {
            margin-right: 15px;
        }
    }

    @media (max-width: 768px) {
        margin: 0 auto;
    }

    @media (max-width: 480px) {
        padding: 0px;
        width: 300px;
        .content-ajuda {
            align-items: start;
            padding-bottom: 0px;
            .container-video {
                width: 116px;
                p {
                    font-size: 14px;
                }
            }

            a {
                width: 128px;
                img {
                    margin-top: 11px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
`;

export const ContainerVideo = styled.div`
    @media (max-width: 480px) {
    }
`;
export const ReenviarContainer = styled.div`
    width:100%;
    padding:0 31px 30px;
`;
