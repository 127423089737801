import styled from "styled-components";

export const Render = styled.div`    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 532px;
    padding:0 20px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.secondary};

    .preview-content {
        display: flex;
        gap: 31px;
        align-items: center;

        .imagem {
            width: 247px;
        }

        .description {
            display: flex;
            flex-direction: column;
            gap: 14px;
            width: 190px;

            font-family: Oxanium;
            color: ${({ theme }) => theme.colors.white};
            .logo {
                width: 146px;
            }

            h2 {
                margin-top: 8px;
                font-weight: 700;
                font-size: 24px;
                line-height: 1.2;
                text-transform: uppercase;
            }

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 16.8px;
                line-height: 1.4;
            }

            div {
                display: flex;
                align-items: center;
                gap: 8px;
                width: 100%;
                height: 44px;
                padding-left: 11px;
                border-radius: 8px;
                background-color: ${({ theme }) => theme.colors.white};

                img {
                    width: 20px;
                }

                span {
                    h4 {
                        font-size: 12px;
                        font-weight: 700;
                        color: ${({ theme }) =>
                            theme.colors.secondary};
                        text-transform: uppercase;
                        padding-top: 4px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 1.4;
                        font-weight: 400;
                        color: ${({ theme }) => theme.colors.primaryDark};
                    }
                }
            }
        }


        &.mobile-content{
            flex-direction: column;
            gap: 10px;

            .imagem {
                width: 166px;
            }

            .description {
                align-items: center;
                .logo {
                    width: 100px;
                }

                h2 {
                    font-size: 20px;
                }

                p{
                    text-align: center;
                }

                div{
                    width:162px;
                    height: 36px;

                    img {
                        width: 20px;
                    }

                    span {
                        h4 {
                            font-size: 10px;
                            text-align: left;
                        }

                        p {
                            font-size: 10px;
                            text-align: left;
                            padding-top: 2px;
                        }
                    }
                }
            }
        }
    }
`;
