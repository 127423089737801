import { Manutence } from "../../../components/System/Manutence";
import { ManutenceProvider } from "../../../Hook/system/useManutence";

export function PageManutence() {
    return (
        <ManutenceProvider>
            <Manutence />
        </ManutenceProvider>    
    );
}
