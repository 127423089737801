import styled from "styled-components";

export const Container = styled.div`
    .container {
        p {
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.textSubtle};
            margin-top: 29px;
            margin-bottom: 19px;
        }
    }
`;

export const FormStyles = styled.div`
    margin: 0 auto;
    max-width: 320px;
    margin-bottom: 5px;

    input{
        height: 40px !important;
        padding-left: 45px !important;
        margin-bottom:8px;

        font-size:14px;
        text-transform:none !important;
    }

    .container-input.chave-cartao:before,
    .container-input.cartao-ativar:before  {
        left: 10px
    }

    .container-input.error:after {
        top:19px;
        right: 40px;
    }

    .container-input.olho button {
        right: -4px;
    }
    
    button {
        height: 40px;
        font-weight: 700;
        text-transform:none;

        &.open-modal-cvv {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
            width: 100%;
            margin-bottom: 68px;
        }
    }

    .cancelar {
        width:100%;
        height: 2rem;
        margin:4px 0 30px;
        
        font-family: Oxanium;
        font-size:14px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.textSubtle};
    }
`;
