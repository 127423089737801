import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { QuillEditorContainer } from "./styles";

type QuillEditorProps = {
    text: string;
    action: (e: string) => void;
    disabled?: boolean;
}

export function QuillEditor({text, action, disabled}:QuillEditorProps) {

    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            ["clean"],
        ],
    };

    return (
        <QuillEditorContainer $isDisabled={!disabled}>
            <ReactQuill
                theme="snow"
                modules={modules}
                value={text}
                onChange={(e) => action(e)}
                readOnly={!disabled}
                min-heigth="159px"
            />
        </QuillEditorContainer>
    );
}
