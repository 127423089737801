import React, { useState } from "react";

import { UseLogin } from "../../../Hook/user/UseLogin";
import { ResendCode } from "../../ResendCode";
import { Timer } from "../../Timer";

export const Reenviar = React.memo(() => {
    const { getCode, user } = UseLogin();

    const [timer, setTimer] = useState(true);

    async function send() {
        setTimer(true);
        await getCode("whatsapp");
    }

    return (
        <>
            {timer && <Timer onComplete={() => setTimer(false)} />}
            {!timer && (
                <ResendCode
                    onResend={async () => await send()}
                    useSMS
                    user={{ mobile_number: user.phone_number || "" }}
                />
            )}
            
        </>
    );
});
