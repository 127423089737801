import styled from "styled-components";
import imgCliente from "../../../assets/img/menu/cliente.svg";

interface ContainerProps {
    icone: string;
}

export const Container = styled.div<ContainerProps>`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e2e3;

    .container-title {
        display: flex;
        align-items: center;
        p {
            position: relative;
            padding-left: 36px;
            background-image: url(attr(data-icon));
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 55%;
                left: 0px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${(props) => props.icone}) no-repeat;
                width: 28px;
                height: 28px;
                z-index: 20;
                transition: background-color 0.5s;
            }
        }
    }

    .container-acoes {
        display: flex;
        align-items: center;
        .container-filtro {
            position: relative;
            img {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
            }
            input {
                width: 100%;
                background-color: transparent;
                padding-right: 20px;
                border: none;
                width: 197px;
            }
        }

        button {
            &.add {
                margin-left: 79px;
            }

            &.action {
                background: transparent;
                border: none;
                margin-left: 32px;
            }
        }
    }
`;

export const NovoHeader = styled.div`
    padding: 40px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e2e3;
    .container-header {
        margin-left: 155px;
        display: flex;
        align-items: center;
        button {
            display: flex;
            align-items: center;
            justify-content: center;

            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};

            img {
                margin-right: 12px;
            }
        }
        &.ml {
            .title {
                margin-left: 140px;
            }
        }

        .title {
            margin-left: 436px;

            &.cliente {
                position: relative;
                padding-left: 36px;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 55%;
                    left: 0px;
                    transform: translateY(-50%);
                    background-color: ${(props) =>
                        props.theme.colors.secondary};
                    -webkit-mask: url(${imgCliente}) no-repeat;
                    width: 24px;
                    height: 24px;
                    z-index: 20;
                    transition: background-color 0.5s;
                }
            }
        }
    }
`;
