import { Text, Box } from "alisson-application";

import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { MyForm, modalInfra, validationModalInfra } from "./config";
import { useContex } from "../Hook/modal";
import { useRegulamento } from "../Hook";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";

import { Button } from "../../../../Button";
import { ModalStyles } from "./styles";
import imgXVerde from "../../../../../../assets/img/x.svg";
import imgCargaModal from "../../../../../../assets/img/carga.svg";

interface FormInfraProps {
    setSucess: (value: boolean) => void;
}

export type keyOfCarga<T> = keyof T;

export function FormInfra(props: FormInfraProps) {
    const { setSucess } = props;
    const { nomeArquivoRegulamento } = useContex();
    const { setFile, value, setValue, file, store } = useRegulamento();
    const { getCampanhaByid } = useCampanha();

    return (
        <ModalStyles>
            <Formik
                enableReinitialize
                initialValues={{
                    ...modalInfra,
                    nome_arq: nomeArquivoRegulamento,
                    nome_regulamento: value,
                }}
                validationSchema={validationModalInfra}
                onSubmit={async (values, actions) => {
                    const formData = new FormData();
                    const keys = Object.keys(values) as keyOfCarga<MyForm>[];

                    keys.forEach((key) => {
                        formData.append(key, values[key]);
                    });

                    // var arq;
                    // if(file){
                    //     arq = new Blob([file], {type: 'application/pdf'})
                    // }

                    formData.append("regulamento", file ? file : "");

                    const response = await store(formData);
                    await getCampanhaByid();

                    if (response.status === 200) {
                        setSucess(true);
                        setFile(null);
                    } else {
                        setSucess(false);
                        setFile(null);
                    }

                    setValue("");
                    setFile(null);
                    actions.resetForm();
                }}
            >
                {(props: FormikProps<MyForm>) => {
                    return (
                        <Form>
                            <div className="container-header">
                                <Text as="p">NOVO REGULAMENTO</Text>
                                <button type="button">
                                    <img src={imgXVerde} alt="" />
                                </button>
                            </div>

                            <div className="container-content">
                                <div className="container-img">
                                    <img src={imgCargaModal} alt="" />
                                </div>

                                <Text as="span">
                                    confira os dados antes de enviar
                                </Text>
                            </div>

                            <div className="container-input-system disabled">
                                <Field
                                    type="text"
                                    value={nomeArquivoRegulamento}
                                    name="nome_arq"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome_arq" />

                            <div className="container-input-system carga disabled">
                                <Field
                                    type="text"
                                    placeholder="TÍTULO DA CARGA"
                                    name="nome_regulamento"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome_regulamento" />

                            <div className="container-input-system">
                                <Field
                                    type="text"
                                    placeholder="OBS."
                                    name="observacao"
                                    component="textarea"
                                />
                            </div>
                            <ErrorMessage component="span" name="observacao" />

                            <Box mt="75px">
                                <Button type="submit">
                                    ADICIONAR Regulamento
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </ModalStyles>
    );
}
