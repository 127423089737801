import { PageLoginSystem } from "../pages/System/Login";
import { PageAuthenticator } from "../pages/System/Authenticator";
import { PagePrecisoDeAjuda } from "../pages/System/PrecisoDeAjuda";

import { PageCliente } from "../pages/System/Cliente";
import { PageRelatorio } from "../pages/System/Relatorio";
import { PageDetalheRelatorio } from "../pages/System/Relatorio/detalhe";
import { PageNovoRelatorio } from "../pages/System/Relatorio/novo";
import { PageNovoCliente } from "../pages/System/Cliente/novo";
import { PageEditarCliente } from "../pages/System/Cliente/editar";
import { PageCampanha } from "../pages/System/Campanha";
import { PageNovaCampanha } from "../pages/System/Campanha/Nova";
import { EditarCampanhaPage } from "../pages/System/Campanha/Editar";
import { PageCarga } from "../pages/System/Carga";
// import { PagePedidos } from "../pages/System/Pedidos";
import { PageAdministracao } from "../pages/System/Administracao";
import { PagePermissao } from "../pages/System/Administracao/permissao";
import { PageAdicionarPermissao } from "../pages/System/Administracao/permissao/adicionar";
import { PageEditarPermissao } from "../pages/System/Administracao/permissao/editar";
import { PageUsuarios } from "../pages/System/Usuarios";
import { PageBuscaUsuarios } from "../pages/System/Usuarios/detalhe";
import { PageNovoAdministrador } from "../pages/System/NovoAdministrador";
import { PageEditarAdministrador } from "../pages/System/EditarAdministrador";
import { LogCargaPage } from "../pages/System/LogCarga";
import { DetalheCampanhaInfra } from "../pages/System/DetalheCampanhaInfra";
import { PageComunicacao } from "../pages/System/Comunicacao/";
import { PageNovaComunicacao } from "../pages/System/Comunicacao/novo";
import { PageDetalheComunicacao } from "../pages/System/Comunicacao/detalhe";
// import { PageNoname } from "../pages/System/Noname";
// import { PageDetalheNoname } from "../pages/System/Noname/Detalhe";
import { PageSegmentacao } from "../pages/System/Segmentacao";
import { PageFaleConosco } from "../pages/System/FaleConosco";
import { PageCupom } from "../pages/System/Cupom";
import { PageNovoCupom } from "../pages/System/Cupom/novo";
import { PageDetalhe } from "../pages/System/Cupom/detalhe";
import { PageWhiteList } from "../pages/System/WhiteList";
import { PageNovaWhiteList } from "../pages/System/WhiteList/Novo";
import { PageEditarWhiteList } from "../pages/System/WhiteList/editar";
import { PageFaq } from "../pages/System/Faq";
import { PageNovoFaq } from "../pages/System/Faq/novo";
import { PageEditarFaq } from "../pages/System/Faq/editar";
import { PageSepro } from "../pages/System/Sepro";
import { PageTermoECondicoesDeUso } from "../pages/System/TermoECondicoesDeUso";
import { PageTermoECondicoesDeUsoNovo } from "../pages/System/TermoECondicoesDeUso/novo";
import { PageAprovacaoTransferencia } from "../pages/System/AprovacaoTransferencia";
import { PageBuscarCartao } from "../pages/System/BuscarCartao";
import { PageUploads } from "../pages/System/Uploads";
import { PageUploadFiles } from "../pages/System/UploadFiles";
import { PageBackOffice } from "../pages/System/BackOffice";
import { PageLogCargaDetalheCredito } from "../pages/System/LogCargaDetalheCredito";

import { PageLogin } from "../pages/Login";
import { PagePerfil } from "../pages/Perfil";
import { PageComprovantes } from "../pages/Comprovante";
import { PagePagamentoBoleto } from "../pages/PagamentoBoleto";
import { PageCampanhaUser } from "../pages/Campanha";
import { PageNotificacoes } from "../pages/Notificacoes";
import { PageDashboard } from "../pages/Dashboard";
import { PageDetalheMovimentacao } from "../pages/DetalheMovimentacao";
import { PageMovimentacao } from "../pages/Movimentacao";
import { PageAdicionarCupom } from "../pages/AdicionarCupom";
import { PageSolicitarResgate } from "../pages/SolicitarResgate";
import { PageNovoCartao } from "../pages/NovoCartaoDigital";
import { PageNovoCartaoFisico } from "../pages/NovoCartaoFisico";
import { PageAtivarCartao } from "../pages/AtivarCartao";
import { PageAtivarcartaoAvulso } from "../pages/AtivarCartaoAvulso";
import { PageCartaoAvulso } from "../pages/CartaoAvulso";
import { PageAlterarSenhaCartao } from "../pages/AlterarSenhaCartao";
import { PageConfirmaEmail } from "../pages/ConfirmaEmail";
import { PageTransferir } from "../pages/Transferir";
import { PageTransferirEntreCarteira } from "../pages/TransferirEntreCarteira";
import { PageDadosCartao } from "../pages/DadosCartao";
import { PageCancelarCartao } from "../pages/CancelarCartao";
import { PageEntrega } from "../pages/Entrega";
import HelloWorld from "../components/teste";
import { Iphone } from "../pages/Iphone";
import { PageTutorias } from "../pages/Tutorias";

import { routesProps } from "./types";
import { PageLogCargaDetalheEstorno } from "../pages/System/LogCargaDetalheEstorno";
import { PageDetalheEstorno } from "../pages/System/DetalheEstorno";
import { PageIdWall } from "../pages/IdWall";
import { PageRelatorioIdWall } from "../pages/System/RelatorioIdWall";
import { PageManutence } from "../pages/System/Manutence"

export const routes: routesProps[] = [
    { path: "/", exact: true, component: PageLogin, isPrivate: false },

    {
        path: "/sistema",
        exact: true,
        component: PageLoginSystem,
        isPrivate: false,
    },
    {
        path: "/sistema/authenticator",
        exact: true,
        component: PageAuthenticator,
        isPrivate: false,
    },

    {
        path: "/precisa-ajuda",
        exact: true,
        component: PagePrecisoDeAjuda,
        isPrivate: false,
    },
    {
        path: "/id-wall",
        exact: true,
        component: PageIdWall,
        isPrivate: false,
    },

    {
        path: "*",
        component: PageLogin,
        isPrivate: true,
    },
];

export const routesUserSide: routesProps[] = [
    {
        path: "/dashboard",
        exact: true,
        component: PageDashboard,
        isPrivate: true,
    },
    {
        path: "/perfil",
        exact: true,
        component: PagePerfil,
        isPrivate: true,
    },
    {
        path: "/tutorial",
        exact: true,
        component: PageTutorias,
        isPrivate: true,
    },
    {
        path: "/pagamento-boleto",
        exact: true,
        component: PagePagamentoBoleto,
        isPrivate: true,
    },
    {
        path: "/comprovantes-boleto",
        exact: true,
        component: PageComprovantes,
        isPrivate: true,
    },
    {
        path: "/campanhas",
        exact: true,
        component: PageCampanhaUser,
        isPrivate: true,
    },
    {
        path: "/detalhe-movimentacao",
        exact: true,
        component: PageDetalheMovimentacao,
        isPrivate: true,
    },
    {
        path: "/confirma-email/:id",
        exact: true,
        component: PageConfirmaEmail,
        isPrivate: true,
    },
    {
        path: "/movimentacao",
        exact: true,
        component: PageMovimentacao,
        isPrivate: true,
    },
    {
        path: "/adicionar-cumpom",
        exact: true,
        component: PageAdicionarCupom,
        isPrivate: true,
    },
    {
        path: "/novo-cartao",
        exact: true,
        component: PageNovoCartao,
        isPrivate: true,
    },
    {
        path: "/novo-cartao-fisico",
        exact: true,
        component: PageNovoCartaoFisico,
        isPrivate: true,
    },
    {
        path: "/solicitar-resgate",
        exact: true,
        component: PageSolicitarResgate,
        isPrivate: true,
    },
    {
        path: "/ativar-cartao",
        exact: true,
        component: PageAtivarCartao,
        isPrivate: true,
    },
    {
        path: "/ativar-cartao-avulso/:id",
        exact: true,
        component: PageAtivarcartaoAvulso,
        isPrivate: true,
    },
    {
        path: "/cartao-avulso",
        exact: true,
        component: PageCartaoAvulso,
        isPrivate: true,
    },
    {
        path: "/alterar-senha-cartao",
        exact: true,
        component: PageAlterarSenhaCartao,
        isPrivate: true,
    },
    {
        path: "/transferir",
        exact: true,
        component: PageTransferir,
        isPrivate: true,
    },
    {
        path: "/transferir-entre-carteiras",
        exact: true,
        component: PageTransferirEntreCarteira,
        isPrivate: true,
    },
    {
        path: "/entrega",
        exact: true,
        component: PageEntrega,
        isPrivate: true,
    },
    {
        path: "/dados-cartao",
        exact: true,
        component: PageDadosCartao,
        isPrivate: true,
    },
    {
        path: "/cancelar-cartao",
        exact: true,
        component: PageCancelarCartao,
        isPrivate: true,
    },
    {
        path: "/notificacoes",
        exact: true,
        component: PageNotificacoes,
        isPrivate: true,
    },
    {
        path: "/codigo-de-barras",
        exact: true,
        component: HelloWorld,
        isPrivate: true,
    },
    {
        path: "/iphone",
        exact: true,
        component: Iphone,
        isPrivate: true,
    },
    {
        path: "*",
        component: PageLogin,
        isPrivate: true,
    },
];

export const routesSystem: routesProps[] = [
    {
        path: "/sistema",
        exact: true,
        component: PageLoginSystem,
        isPrivate: true,
        permission: "usuario.me",
    },
    {
        path: "/sistema/cliente",
        exact: true,
        component: PageCliente,
        isPrivate: true,
        permission: "cliente.index",
    },
    {
        path: "/sistema/relatorio",
        exact: true,
        component: PageRelatorio,
        isPrivate: true,
        permission: "cliente.index",
    },
    {
        path: "/sistema/relatorio/novo",
        exact: true,
        component: PageNovoRelatorio,
        isPrivate: true,
        permission: "cliente.index",
    },
    {
        path: "/sistema/relatorio/:id",
        exact: true,
        component: PageDetalheRelatorio,
        isPrivate: true,
        permission: "cliente.index",
    },
    {
        path: "/sistema/cliente/novo",
        exact: true,
        component: PageNovoCliente,
        isPrivate: true,
        permission: "cliente.store",
    },
    {
        path: "/sistema/cliente/editar/:id",
        exact: true,
        component: PageEditarCliente,
        isPrivate: true,
        permission: "cliente.update",
    },
    {
        path: "/sistema/campanha",
        exact: true,
        component: PageCampanha,
        isPrivate: true,
        permission: "campanha.index",
    },
    {
        path: "/sistema/campanha/nova",
        exact: true,
        component: PageNovaCampanha,
        isPrivate: true,
        permission: "campanha.store",
    },
    {
        path: "/sistema/campanha/editar/:id",
        exact: true,
        component: EditarCampanhaPage,
        isPrivate: true,
        permission: "campanha.update",
    },
    {
        path: "/sistema/campanha/carga/:id",
        exact: true,
        component: PageCarga,
        isPrivate: true,
        permission: "campanha.show",
    },
    {
        path: "/sistema/campanha/carga/:id/detalhe-credito",
        exact: true,
        component: PageLogCargaDetalheCredito,
        isPrivate: true,
        permission: "campanha.show",
    },
    {
        path: "/sistema/campanha/carga/:id/log-estorno",
        exact: true,
        component: PageLogCargaDetalheEstorno,
        isPrivate: true,
        permission: "campanha.show",
    },
    {
        path: "/sistema/campanha/carga/:id/detalhe-estorno",
        exact: true,
        component: PageDetalheEstorno,
        isPrivate: true,
        permission: "carga.show",
    },
    {
        path: "/sistema/campanha/carga/:id/log",
        exact: true,
        component: LogCargaPage,
        isPrivate: true,
        permission: "carga.logs",
    },
    {
        path: "/sistema/campanha/carga/:id/detalhe",
        exact: true,
        component: DetalheCampanhaInfra,
        isPrivate: true,
        permission: "carga.show",
    },
    // {
    //     path: "/sistema/pedidos",
    //     exact: true,
    //     component: PagePedidos,
    //     isPrivate: true,
    //     permission: "",
    // },
    {
        path: "/sistema/administracao",
        exact: true,
        component: PageAdministracao,
        isPrivate: true,
        permission: "role.index",
    },
    {
        path: "/sistema/administracao/permissao",
        exact: true,
        component: PagePermissao,
        isPrivate: true,
        permission: "role.index",
    },
    {
        path: "/sistema/administracao/permissao/adicionar",
        exact: true,
        component: PageAdicionarPermissao,
        isPrivate: true,
        permission: "role.index",
    },
    {
        path: "/sistema/administracao/permissao/editar/:id",
        exact: true,
        component: PageEditarPermissao,
        isPrivate: true,
        permission: "role.index",
    },
    {
        path: "/sistema/perfil",
        exact: true,
        component: PageAdministracao,
        isPrivate: true,
        permission: "role.index",
    },
    {
        path: "/sistema/administracao/novo",
        exact: true,
        component: PageNovoAdministrador,
        isPrivate: true,
        permission: "usuario.store",
    },
    {
        path: "/sistema/administracao/:id",
        exact: true,
        component: PageEditarAdministrador,
        isPrivate: true,
        permission: "usuario.store",
    },
    {
        path: "/sistema/usuarios",
        exact: true,
        component: PageUsuarios,
        isPrivate: true,
        permission: "usuario.search",
    },
    {
        path: "/sistema/usuario/:id",
        exact: true,
        component: PageBuscaUsuarios,
        isPrivate: true,
        permission: "usuario.index",
    },
    {
        path: "/sistema/comunicacao",
        exact: true,
        component: PageComunicacao,
        isPrivate: true,
        permission: "acao.index",
    },
    {
        path: "/sistema/comunicacao/novo",
        exact: true,
        component: PageNovaComunicacao,
        isPrivate: true,
        permission: "acao.store",
    },
    {
        path: "/sistema/comunicacao/detalhe/:id",
        exact: true,
        component: PageDetalheComunicacao,
        isPrivate: true,
        permission: "acao.show",
    },
    {
        path: "/sistema/segmentacao",
        exact: true,
        component: PageSegmentacao,
        isPrivate: true,
    },
    {
        path: "/sistema/idwall",
        exact: true,
        component: PageRelatorioIdWall,
        isPrivate: true,
        permission: "cliente.index",
    },
    {
        path: "/sistema/manutencao",
        exact: true,
        component: PageManutence,
        isPrivate: true,
        permission: "cliente.index",
    },
    // {
    //     path: "/sistema/cartao-virtual",
    //     exact: true,
    //     component: PageNoname,
    //     isPrivate: true,
    //     permission: "carga.index",
    // },
    // {
    //     path: "/sistema/cartao-virtual/detalhe/:id",
    //     exact: true,
    //     component: DetalheNoname,
    //     isPrivate: true,
    // },
    // {
    //     path: "/sistema/cartao-virtual/detalhe/:id",
    //     exact: true,
    //     component: PageDetalheNoname,
    //     isPrivate: true,
    //     permission: "carga.show",
    // },
    // {
    //     path: "/sistema/cartao-virtual/log/:id",
    //     exact: true,
    //     component: LogCargaPage,
    //     isPrivate: true,
    //     permission: "carga.logs",
    // },
    {
        path: "/sistema/fale-conosco",
        exact: true,
        component: PageFaleConosco,
        isPrivate: true,
        permission: "helpdesk.index",
    },
    {
        path: "/sistema/cupom",
        exact: true,
        component: PageCupom,
        isPrivate: true,
        permission: "cupom.index",
    },
    {
        path: "/sistema/cupom/novo",
        exact: true,
        component: PageNovoCupom,
        isPrivate: true,
        permission: "cupom.store",
    },
    {
        path: "/sistema/cupom/detalhe/:id",
        exact: true,
        component: PageDetalhe,
        isPrivate: true,
        permission: "cupom.show",
    },
    {
        path: "/sistema/white-list",
        exact: true,
        component: PageWhiteList,
        isPrivate: true,
        permission: "whitelist_document.index",
    },
    {
        path: "/sistema/white-list/adicionar",
        exact: true,
        component: PageNovaWhiteList,
        isPrivate: true,
        permission: "whitelist_document.store",
    },
    {
        path: "/sistema/white-list/editar/:id",
        exact: true,
        component: PageEditarWhiteList,
        isPrivate: true,
        permission: "whitelist_document.show",
    },
    {
        path: "/sistema/faq",
        exact: true,
        component: PageFaq,
        isPrivate: true,
        permission: "faq.index",
    },
    {
        path: "/sistema/faq/novo",
        exact: true,
        component: PageNovoFaq,
        isPrivate: true,
        permission: "faq.store",
    },
    {
        path: "/sistema/faq/editar/:id",
        exact: true,
        component: PageEditarFaq,
        isPrivate: true,
        permission: "faq.show",
    },
    {
        path: "/sistema/serpro",
        exact: true,
        component: PageSepro,
        isPrivate: true,
        permission: "reports.serpro.requests_count",
    },
    {
        path: "/sistema/buscar-cartao",
        exact: true,
        component: PageBuscarCartao,
        isPrivate: true,
        permission: "cartao.search",
    },
    {
        path: "/sistema/termo-e-condicoes-de-uso",
        exact: true,
        component: PageTermoECondicoesDeUso,
        isPrivate: true,
        permission: "termo_politica.index",
    },
    {
        path: "/sistema/termo-e-condicoes-de-uso/novo",
        exact: true,
        component: PageTermoECondicoesDeUsoNovo,
        isPrivate: true,
        permission: "termo_politica.store",
    },
    {
        path: "/sistema/aprovacao-de-transferencia",
        exact: true,
        component: PageAprovacaoTransferencia,
        isPrivate: true,
        permission: "termo_politica.store",
    },
    {
        path: "/sistema/upload",
        exact: true,
        component: PageUploads,
        isPrivate: true,
        permission: "file.index",
    },
    {
        path: "/sistema/upload/files",
        exact: true,
        component: PageUploadFiles,
        isPrivate: true,
        permission: "file.store",
    },
    {
        path: "/sistema/backoffice",
        exact: true,
        component: PageBackOffice,
        isPrivate: true,
        permission: "file.store",
    },
    {
        path: "*",
        component: PageLoginSystem,
        isPrivate: true,
    },
];
