import * as Yup from "yup";

export interface MyForm {
    nome_arq: string;
    nome_regulamento: string;
    observacao: string;
}

export const modalInfra: MyForm = {
    nome_arq: "",
    nome_regulamento: "",
    observacao: "",
};

export const validationModalInfra = Yup.object().shape({
    nome_arq: Yup.string().required("Campo Obrigatório"),
    nome_regulamento: Yup.string().required("Campo Obrigatório"),
    observacao: Yup.string(),
});
