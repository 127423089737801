/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";

import { useLogin } from "../../../../../../Hook/system/useLogin";
import { CodeInput } from "../../../../../CodeInput";
import { ResendCode } from "../../../../../ResendCode";
import { Timer } from "../../../../../Timer";
import { getAuthMessage } from "../../../../../../util/getAuthMessage";

import { Loader } from "../../../../../Loader";
import {
    Description,
    DisabledInput,
    PasswordContent,
    PasswordStepContainer,
    Title,
    LoaderContainer,
} from "./styles";

interface IConfirmTokenProps {
    onNextPage: () => void;
}

export const ConfirmToken = ({ onNextPage }: IConfirmTokenProps) => {
    const {
        confirmAuthCode,
        genereteAuthCode,
        user,
        setModalConfirm,
        setAuthenticator,
    } = useLogin();

    const formatedPhoneNumber = user.user.phone_number || "";

    const [code, setCode] = useState("");
    const [timerHasTimeOut, setTimerHasTimeOut] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleTimerHasTimeOut = useCallback(async () => {
        setTimerHasTimeOut(true);
    }, []);

    const handleConfirmToken = useCallback(async () => {
        setIsLoading(true)
        const isFirstUpdate = user.user.first_update_password === 0;
        const { success } = await confirmAuthCode(code);

        if (success) {
            if (isFirstUpdate && onNextPage) {
                return onNextPage();
            }

            setModalConfirm(false);
            setAuthenticator(true);
        }
        setIsLoading(false)
    }, [
        code,
        confirmAuthCode,
        onNextPage,
        setModalConfirm,
        setAuthenticator,
        user,
    ]);

    const handleResendToken = useCallback(() => {
        setTimerHasTimeOut(false);
        genereteAuthCode();
    }, []);

    useEffect(() => {
        genereteAuthCode();
    }, []);


    useEffect(() => {

        const withOutBlanks = code.replaceAll(" ", "");
        if (withOutBlanks.length < 6) return;

        const delayInputCheck = setTimeout(() => {
            handleConfirmToken()
            return () => clearTimeout(delayInputCheck);
        }, 1500);
        return () => clearTimeout(delayInputCheck);
        
    }, [code]);

    const authMessage = getAuthMessage();

    return (
        <PasswordStepContainer data-testid="password-modal">
            <PasswordContent>
                <Title>código de verificação</Title>
                <Description>
                    <b>PREENCHA COM O CÓDIGO DE VERIFICAÇÃO</b><br />
                    {authMessage}.
                </Description>

                <DisabledInput disabled placeholder={formatedPhoneNumber} />
                {isLoading 
                ? <LoaderContainer>
                    <Loader width={71} height={71}/> 
                </LoaderContainer>
                : <CodeInput onChange={(e) => setCode(e)} />}

                {!timerHasTimeOut && (
                    <Timer onComplete={handleTimerHasTimeOut} />
                )}

                {timerHasTimeOut && (
                    <ResendCode
                        onResend={handleResendToken}
                        sentTo={"WhatsApp"}
                        useWhatsApp
                        user={{
                            mobile_number: formatedPhoneNumber,
                        }}
                    />
                )}
            </PasswordContent>
        </PasswordStepContainer>
    );
};
