// import { useEffect} from "react";

import { Header } from "./header";
import { Container, Title} from "./styles";
import imgOlho from "../../../../src/assets/img/olho.svg";
import comunicacao from "../../../../src/assets/img/sistema/menu/comunicacao-gray.svg";

import { IOSSwitch } from "../Switch";

import { Button } from "../Button";
import { Box, Radio } from "@material-ui/core";
import { useManutence } from "../../../Hook/system/useManutence";
import {
    LocalizationProvider,
    MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/pt-BR";
import { useEffect} from "react";
import { QuillEditor } from "../QuillEditor";
import { ManutenceScreen } from "../ManutenceScreen";

export function Manutence() {
    const { 
        fetch, 
        update,
        data, 
        loading, 
        handleActive,                 
        hasChange,
        custom, 
        handleCustom,
        handleStartDate,
        handleEndDate,
        handleTitle,
        handleWarning,
        validateData,
    } = useManutence();

    useEffect(() => {
        fetch();
    }, [fetch]);


    return (
        <Container>
            <Header />
            <main>
                <div className="control">
                    <div className="active">
                        <p>Modo de Manutenção</p>
                        <IOSSwitch
                            checked={data.active}
                            onChange={(e) => handleActive(e.target.checked)}
                            disabled={loading}
                        />
                    </div>
                    <div
                        className={`setup ${
                            loading ? "disabled" : ""
                        }`}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={esLocale}
                        >
                            <div className="date">
                                <MobileDateTimePicker
                                    toolbarTitle="SELECIONE DATA E HORA"
                                    defaultCalendarMonth={new Date()}
                                    ampm={false}
                                    value={data.startDate}

                                    minDateTime={ new Date()}
                                    maxDateTime={data.endDate ? new Date(data.endDate) : undefined}
                                    onChange={(newValue: Date | null) => {
                                        if (newValue instanceof Date) {
                                            handleStartDate(newValue);
                                        }
                                    }}
                                    renderInput={({
                                        inputRef,
                                        inputProps,
                                        InputProps,
                                    }) => (
                                        <Box
                                            className={`${ "container-input-system calendario" }`}
                                        >
                                            <input
                                                ref={inputRef}
                                                {...inputProps}
                                                placeholder={"Data de início ex: 10/02/2025 10:30"}
                                            />
                                            {InputProps?.endAdornment}
                                        </Box>
                                    )}
                                />

                            </div>
                            <div className="date">
                                <MobileDateTimePicker
                                    toolbarTitle="SELECIONE DATA E HORA"
                                    defaultCalendarMonth={new Date()}
                                    ampm={false}
                                    value={data.endDate}
                                    
                                    minDateTime={data.startDate ? new Date(data.startDate) : new Date()}
                                    onChange={(newValue: Date | null) => {
                                        if (newValue instanceof Date) {
                                            handleEndDate(newValue);
                                        }
                                    }}
                                    renderInput={({
                                        inputRef,
                                        inputProps,
                                        InputProps,
                                    }) => (
                                        <Box
                                            className={`${ "container-input-system calendario" }`}
                                        >
                                            <input
                                                ref={inputRef}
                                                {...inputProps}
                                                placeholder={"Data de saída ex: 25/03/2025 22:30"}
                                            />
                                            {InputProps?.endAdornment}
                                        </Box>
                                    )}
                                />

                            </div>
                        </LocalizationProvider>

                        <div className="custom">
                            <span>
                                <Radio
                                    checked={!custom}
                                    onChange={() => handleCustom(false)}
                                    name="radio-buttons"
                                    inputProps={{
                                        "aria-label": "Texto padrão",
                                    }}
                                    className="radio"
                                />
                                <p>Texto padrão </p>
                            </span>

                            <span>
                                <Radio
                                    checked={custom}
                                    onChange={() => handleCustom(true)}
                                    name="radio-buttons"
                                    inputProps={{
                                        "aria-label": "Texto personalizado",
                                    }}
                                    className="radio"
                                />
                                <p>Texto personalizado</p>
                            </span>
                        </div>
                        <div className="message">
                            <img src={comunicacao} alt="" />

                            <input
                                className="title"
                                type="text"
                                name={"title"}
                                placeholder="Título"
                                value={data.title}
                                onChange={(e) => handleTitle(e.target.value)}
                            />

                            <QuillEditor text={data.warning} action={(e)=>handleWarning(e)} disabled={custom}/>

                            {!custom && <div className="defaultLayer" onClick={()=>handleCustom(true)}></div>}

                            <Button
                                disabled={!hasChange || loading || validateData().length > 0}
                                onClick={()=>update()}
                                isLoading={loading}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="preview">
                    <div className="subTitle">
                        <img src={imgOlho} alt="" />
                        <p>Pré-visualização</p>
                    </div>

                    <div
                        className={`screens ${
                            !data.active || loading ? "disabled" : ""
                        }`}
                    >
                        <div className="desktop">
                            <Title>Desktop</Title>
                            <ManutenceScreen />
                        </div>
                        <div className="mobile">
                            <Title>Mobile</Title>
                            <ManutenceScreen mobile />
                        </div>
                    </div>
                </div>
            </main>
        </Container>
    );
}
