import { useContext, createContext, useState, useEffect} from "react";
import { api } from "../../services";
import { toast } from "react-toastify";
import { getLocalStore } from "../../util/helper";

interface Data {
        active: boolean
        startDate: string
        endDate: string
        title: string
        warning: string
        userId: string
}

interface useManutenceProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseManutence {
    fetch(): Promise<Data | undefined>;
    update(): void;
    data: Data;
    loading: boolean;
    defaultData: Data;
    handleActive(value: boolean): void;
    setData(data: Data): void;
    setHasChange:(value:boolean)=>void;
    hasChange: boolean; 
    handleCustom:(value:boolean)=>void,
    custom: boolean,
    handleStartDate(date: Date): void;
    handleEndDate(date: Date): void;
    handleTitle(value: string): void;
    handleWarning(value: string): void;
    validateData(): string[];
}

const UseManutenceContext = createContext<UseManutence>({} as UseManutence);

export function ManutenceProvider(props: useManutenceProviderProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [custom, setCustom] = useState(false);

    const defaultData = {
            active: false,
            startDate: "",
            endDate: "",
            title: "Fique atento!",
            warning: "A Plataforma <b>YETZPAY</b> está passando por uma <b>ATUALIZAÇÃO</b> para oferecer ainda mais recursos.",
            userId: ""
    }

    const [data, setData] = useState<Data>({...defaultData});
    

    async function fetch() {
        // try {
        //     setLoading(true);
        //     const { data } = await api.post<Response>(
        //         `/Manutence`
        //     );
        //     setData(data.data);

        //     if ( data.data.title !== defaultData.title && data.data.warning !== defaultData.warning ) {
        //         setCustom(true);
        //     }

        //     setLoading(false);
        //     return data.data;
        // } catch (error:any) {
        //     setLoading(false);
        //     toast.error(error.response.data.message);
        // }

        return undefined
    }

    useEffect(() => {
        if(!custom){
            setData( { ...data, warning: defaultData.warning})
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custom]);

    function dateIsValid(date:string){
        return isNaN(Date.parse(date));
    }

    function validateData() {

            const errors = []

            if (dateIsValid(data.startDate)) {
                errors.push("Data de início inválida");
            }

            if (dateIsValid(data.endDate)) {
                errors.push("Data de término inválida");
            }

            if (dateIsValid(data.startDate) && dateIsValid(data.endDate) && new Date(data.startDate) > new Date(data.endDate)) {
                errors.push("Data inicial deve ser maior que a de término");
            }

            if (data.title === "") {
                errors.push("Título é obrigatório");
            }

            if (data.warning === "") {
                errors.push("Mensagem de manutenção é obrigatória");
            }
            return errors 
        }

    async function update() {

        const validate = validateData();

        if (validate.length  ) {
            for (const error of validate) {
                toast.error(error);
            }
            return;
        }

        const id = getLocalStore()?.id.toString();
        
        setData({ ...data, userId: id  });

        try {
            setLoading(true);
            await api.put(`Manutence`, { ...data});
            toast.success("Mensagem de manutenção atualizada com sucesso!");
            setHasChange(false);
            setLoading(false);

        } catch (error: any) {
            setLoading(false);
            toast.error("Erro ao atualizar mensagem de manutenção");
            return error;
        }
    }

    function handleActive(value: boolean) {
        setHasChange(true);
        setData({ ...data, active: value} );
    }

    function handleCustom(value: boolean) {
        handleTitle(defaultData.title);

        setHasChange(true);
        setCustom(value);
    }

    function handleStartDate(date: Date) {
        setHasChange(true);
        setData({ ...data, startDate: date.toLocaleString()} );
    }

    function handleEndDate(date: Date) {
        setHasChange(true);
        setData({ ...data, endDate: date.toLocaleString() } );
    }

    function handleTitle(value: string) {
        setHasChange(true);
        setData( { ...data, title: value })
    }

    function handleWarning(value: string) {
        setHasChange(true);
        setData({ ...data, warning: value  })
    }

    return (
        <UseManutenceContext.Provider
            value={{
                fetch,
                update,
                data,
                loading,
                defaultData:defaultData,
                handleActive,
                setData,
                hasChange,
                setHasChange,
                handleCustom,
                custom,
                handleStartDate,
                handleEndDate,
                handleTitle,
                handleWarning,
                validateData,
            }}
        >
            {children}
        </UseManutenceContext.Provider>
    );
}

export function useManutence() {
    const context = useContext(UseManutenceContext);
    return context;
}