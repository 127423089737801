import ReactCodeInput from "react-code-input";

import { Text } from "alisson-application";
import { useTranslation } from "../../contexts/Localization";
import { CodeInputContainer } from "./styles";

interface ICodeInput {
    showError?: boolean;
    fields?: number;
    onChange?: ((value: string) => void) | undefined;
}

export const CodeInput = ({ showError, onChange, fields = 6 }: ICodeInput) => {
    const { t } = useTranslation();

    return (
        <CodeInputContainer showError={showError}>
            <ReactCodeInput
                type="tel"
                fields={fields}
                name={"code"}
                inputMode={"tel"}
                onChange={onChange}
            />

            {showError && (
                <Text as="small" color="failure" fontSize="xss">
                    {t("Código inválido")}
                </Text>
            )}
        </CodeInputContainer>
    );
};
