import { memo } from "react";
import { Text } from "alisson-application";

import { useContex, ModalInfraProvider } from "../Hook/modal";
import { useRegulamento } from "../Hook";

import { Modal } from "../../../../../Modal";
import { NovaCargaInfra } from "../Modal";

import imgSetaCima from "../../../../../../assets/img/seta-cima.svg";
import imgX from "../../../../../../assets/img/x.svg";

import { UploadStyles } from "./styles";

function Upload() {
    const { modalRegulamento, setModalRegulamento, setNomeArquivoRegulamento } =
        useContex();
    const { file, setFile, setValue, value } = useRegulamento();

    // useEffect(() => {
    //     if (campanhaById.regulamento) {
    //         setDisabled(true);
    //     } else {
    //         setDisabled(false);
    //     }
    // }, [campanhaById.regulamento]);

    return (
        <UploadStyles>
            <div className="container-text">
                <Text as="p">
                    USE O CAMPO AO LADO PARA INSERIR o regulamento.
                </Text>
            </div>

            <div className="container-input-file">
                <div className={``}>
                    <input
                        type="text"
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        placeholder="REGULAMENTO"
                    />
                    <br />

                    {file?.name && !value ? (
                        <span>Campo obrigatorio</span>
                    ) : null}
                </div>

                <label htmlFor="fileRegulamento" className={``}>
                    <div className="container-text">
                        {file?.name ? (
                            <Text color="primaryDark" as="span" fontSize="xs">
                                {file.name}
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setNomeArquivoRegulamento("");
                                        setFile(null);
                                    }}
                                >
                                    <img src={imgX} alt="" />
                                </button>
                            </Text>
                        ) : (
                            <Text color="primaryDark" as="span" fontSize="xs">
                                Selecione o arquivo
                            </Text>
                        )}
                    </div>

                    <div
                        className={`container-img ${
                            file?.name && value ? "active" : ""
                        }`}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setModalRegulamento(true);
                        }}
                    >
                        <img src={imgSetaCima} alt="" />
                    </div>
                </label>

                <input
                    type="file"
                    id="fileRegulamento"
                    accept=".pdf"
                    onChange={(e) => {
                        setNomeArquivoRegulamento(
                            e.target.files?.length ? e.target.files[0].name : ""
                        );
                        setFile(
                            e.target.files?.length ? e.target.files[0] : null
                        );
                    }}
                />
            </div>

            <Modal component={NovaCargaInfra} active={modalRegulamento} />
        </UploadStyles>
    );
}

export function WrapperUpload() {
    return (
        <ModalInfraProvider>
            <Upload />
        </ModalInfraProvider>
    );
}

export const Regulamento = memo(WrapperUpload);
