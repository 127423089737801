import { Text } from "alisson-application";
import imgWhats from "../../../../assets/img/whats-verificacao.svg";
import { Container } from "./styles";
import { getAuthMessage } from "../../../../util/getAuthMessage";

export function Header() {

    const authMessage = getAuthMessage();
    
    return (
        <Container>
            <div className="container-img">
                <img src={imgWhats} alt="" />
            </div>
            <Text as="h3">PREENCHA COM O CÓDIGO DE VERIFICAÇÃO</Text>
            <Text as="p" color="primaryDark">
                {authMessage}.
            </Text>
        </Container>
    );
}
