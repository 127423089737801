import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../services/";
import { getLocalStore } from "../../util/helper";
import { LocalStoreProps, Variant } from "../types";

import { MyFormValues } from "../../components/System/Login/types";
import { Pessoa } from "../user/UseLogin";

interface UseLoginProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface FuncReturn {
    success: boolean;
    message?: string;
}
interface UseLoginData {
    login: (values: MyFormValues) => Promise<void>;
    loading: boolean;
    phoneNumber: string;
    modalConfirm: boolean;
    setModalConfirm: (value: boolean) => void;
    setAuthenticator: (value: boolean) => void;
    getCode(type: Variant): Promise<any>;
    genereteAuthCode: () => void;
    confirmAuthCode: (values: string) => Promise<any>;
    generateQRCode: () => Promise<string>;
    updateFirstPassword: (props: IUpdateFirstPass) => Promise<FuncReturn>;
    confirmeAuthCode(code: string): Promise<boolean>;
    authenticator: boolean;
    user: UserData;
}

interface User {
    id: number;
    name: string;
    email: string;
    document: string;
    phone_number: string | null;
    status: number;
    register_status: number;
    image: string;
    created_at: string;
    updated_at: string;
    saldo: number;
    access_token: string;
    google_2fa_secret_enabled: number;
    first_update_password: 0 | 1;
}

interface UserData {
    access_token: string;
    user: User;
    permissions_: string[];
    permissions_count: number;
}

interface confirmAuthCodeProps {
    success: boolean;
}

interface IUpdateFirstPass {
    new_password: string;
    new_password_confirmation: string;
    current_password: string;
}

const UsePassosContext = createContext<UseLoginData>({} as UseLoginData);

export function LoginProvider(props: UseLoginProviderProps) {
    const { children } = props;

    const [tempLocalStore, setTempLocalStore] = useState<LocalStoreProps>(
        {} as LocalStoreProps
    );
    const [user, setUser] = useState<UserData>({} as UserData);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState<boolean>(false);
    const [authenticator, setAuthenticator] = useState<boolean>(false);

    async function login(values: MyFormValues) {
        try {
            setLoading(true);
            const { data } = await api.post<UserData>("/login", values);

            const templocalStorage: LocalStoreProps = {
                first_update_password: data.user?.first_update_password,
                name: data.user.name,
                token_adm: data.access_token,
                token_user: getLocalStore().token_user
                    ? getLocalStore().token_user
                    : "",
                nome_da_mae: "",
                email: "",
                id: data.user.id,
                phone_number: data.user.phone_number
                    ? data.user.phone_number
                    : "",
                type: "admin",
                first_login: getLocalStore().first_login
                    ? getLocalStore().first_login
                    : 0,
                data_nascimento: "",
                documento: "",
                campanha_user: {
                    ...getLocalStore().campanha_user,
                },
                image: "",
                permissions: data.permissions_.reduce((acc: any, item) => {
                    acc[item] = true || false;

                    return acc;
                }, {}),
                permissions_count: data.permissions_count,
                pessoa: {} as Pessoa,
                time: new Date(),
            };

            setTempLocalStore(templocalStorage);
            setUser(data);
            setModalConfirm(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setModalConfirm(false);
        }
    }

    async function confirmAuthCode(code: string) {
        try {
            const { data } = await api.post<confirmAuthCodeProps>(
                "/usuario/confirm-auth-code",
                { code, is_login: true },
                {
                    headers: {
                        Authorization: `Bearer ${tempLocalStore.token_adm}`,
                    },
                }
            );

            if (!data.success) throw new Error("Codígo Inválido!");

            return data;
        } catch (error: any) {
            toast.error("Code inválido");
            return error;
        }
    }

    async function generateQRCode() {
        try {
            const response = await api.post<string>(
                "usuario/google-2fa-generate-qrcode",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${tempLocalStore.token_adm}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.log(error);
            return "";
        }
    }

    async function confirmeAuthCode(code: string) {
        try {
            const response = await api.post<any>(
                "usuario/google-2fa-confirm-secret",
                { secret: code },
                {
                    headers: {
                        Authorization: `Bearer ${tempLocalStore.token_adm}`,
                    },
                }
            );
            if (response.data.success) {
                localStorage.setItem("user", JSON.stringify(tempLocalStore));
                window.location.href = "/sistema/usuarios";
                // window.location.reload();
            }

            return response.data.success;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async function genereteAuthCode() {
        try {
            const { data } = await api.post<
                confirmAuthCodeProps & { phone_number: string, authType?: string}
            >(
                "/usuario/generate-auth-code",
                { type: "whatsapp" },
                {
                    headers: {
                        Authorization: `Bearer ${tempLocalStore.token_adm}`,
                    },
                }
            );

            setPhoneNumber(data.phone_number);
            localStorage.setItem("authType", data.authType ?? "");

            return data;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function getCode(type: Variant) {
        const headers = {
            authorization: `Bearer ${tempLocalStore.token_adm}`,
        };

        try {
            await api.post(
                `/usuario/generate-auth-code`,
                { type: "whatsapp" },
                { headers }
            );
            toast.success("Código enviado com sucesso");
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error?.response;
        }
    }

    async function updateFirstPassword(props: IUpdateFirstPass) {
        const headers = {
            authorization: `Bearer ${tempLocalStore.token_adm}`,
        };

        try {
            await api.patch(
                `/usuario/first-update-password`,
                { ...props, user_name: tempLocalStore.name },
                { headers }
            );

            return { success: true };
        } catch (error: any) {
            return { success: false, message: error?.response?.data?.message };
        }
    }

    return (
        <UsePassosContext.Provider
            value={{
                loading,
                login,
                user,
                confirmeAuthCode,
                updateFirstPassword,
                setAuthenticator,
                authenticator,
                getCode,
                generateQRCode,
                phoneNumber,
                modalConfirm,
                setModalConfirm,
                genereteAuthCode,
                confirmAuthCode,
            }}
        >
            {children}
        </UsePassosContext.Provider>
    );
}

export function useLogin() {
    const context = useContext(UsePassosContext);

    return context;
}
