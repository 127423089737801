import styled from "styled-components";

interface QuillEditorContainerProps {
    $isDisabled?: boolean;
}

export const QuillEditorContainer = styled.div<QuillEditorContainerProps>`
    width: 100%;

    .quill {
  
      .ql-editor {
        min-height: 300px;
      }
  
      
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow{
          background-color: #efefef;
      }

      .ql-toolbar.ql-snow{
          border: 1px solid #ccc;
          border-radius: 4px 4px 0 0;
          }
      
      
      .ql-container.ql-snow {
          border-radius: 0 0 4px 4px;
        .ql-editor {
          min-height: 139px;
          color: ${({ theme }) => theme.colors.primary};
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.1em;
          line-height: 1.5;
        }
      }

      .ql-toolbar.ql-snow  {
        display:flex;
        gap:16px;
        span {
            display:flex;
            gap:4px;
            margin:0;
            button {
                width: 20px;
            }
        }
      }
  `;
