import { useEffect, useState } from "react";
import { Item } from "./config";
import { useRelatorioIdWall } from "../../../Hook/system/useRelatorioIdWall";
import { api } from "../../../services";

import Table from "../Table";
import { Header } from "./header";
import { Container, Actions } from "./styles";
import { Loader } from "../../Loader";
import { GridColumns } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";

import calendario from "../../../assets/img/calendario.svg";
import lupa from "../../../assets/img/lupa-branca.svg";
import download from "../../../assets/img/dowload.svg";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/pt-BR";
import { formatCurrentMoney } from "../../../util/helper";

import { Button } from "../Button";

export function RelatorioIdWall() {
    const totalWidth =
        window.screen.width < 1200 ? 800 : window.screen.width - 300;
    const width = totalWidth / 5;
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filtredData, setFiltredData] = useState<Item[]>([]);
    const [loadingExportar, setLoadingExportar] = useState(false);  

    const { fetch, response, loading } = useRelatorioIdWall();

    useEffect(() => {
        if (search === "") {
            setFiltredData(response.data?.dataTable);
            return;
        }

        const filteredData = response.data?.dataTable.filter((row) => {
            const lowerCaseSearch = search.toLowerCase().trim();

            if (
                row.investimento.toLowerCase().includes(lowerCaseSearch) ||
                row.tipo.toLowerCase().includes(lowerCaseSearch) ||
                formatCurrentMoney(row.valorUnitario).includes(
                    lowerCaseSearch
                ) ||
                (row.total && row.total.toString().includes(lowerCaseSearch)) ||
                formatCurrentMoney(row.valorTotal).includes(lowerCaseSearch)
            )
                return row;
            return null;
        });

        setFiltredData(filteredData);
    }, [response, search]);

    const dados =
        filtredData?.map((item: Item, index) => {
            return {
                id: index.toString(),
                tipoInvestimento: item?.investimento,
                tipo: item?.tipo,
                valorUnitario: "R$ " + formatCurrentMoney(+item?.valorUnitario),
                total: item?.total,
                valorTotal: "R$ " + formatCurrentMoney(+item?.valorTotal),
            };
        }) || [];

    const totais = () => {
        let totalQtd = 0;
        let totalValue = 0;

        if (filtredData.length > 0) {
            for (const item of filtredData) {
                totalQtd += item.total || 0;
                const totalValueQG = item.valorTotal || "0";
                totalValue += +totalValueQG;
            }

            return [
                {
                    id: "totais",
                    tipoInvestimento: "",
                    tipo: "",
                    valorUnitario: "Total",
                    total: totalQtd,
                    valorTotal: "R$ " + formatCurrentMoney(totalValue),
                },
            ];
        }

        return [];
    };

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 0,
        },
        {
            field: "tipoInvestimento",
            headerName: "TIPO INVESTIMENTO",
            width,
        },
        {
            field: "tipo",
            headerName: "ITEM TIPOS DE CONSULTAS",
            width,
        },
        {
            field: "valorUnitario",
            headerName: "VALOR UNITÁRIO",
            width,
            align: "center",
        },
        {
            field: "total",
            headerName: "QUANTIDADE YETZ",
            width,
            align: "center",
        },
        {
            field: "valorTotal",
            headerName: "VALOR TOTAL",
            width,
            align: "center",
        },
    ];

    function handleStartDate(e: string) {
        setStartDate(e);

        if (endDate === "") return;

        const selectedStartDate = new Date(e);
        const selectedEndDate = new Date(endDate);

        if (selectedStartDate > selectedEndDate) {
            setEndDate(e);
        }
    }

    function handleEndDate(e: string) {
        setEndDate(e);

        if (startDate === "") return;

        const selectedStartDate = new Date(startDate);
        const selectedEndDate = new Date(e);

        if (selectedStartDate > selectedEndDate) {
            setStartDate(e);
        }
    }

    function handleFetch() {
        setSearch("");
        setFiltredData([]);
        fetch(formatDate(startDate), formatDate(endDate));
    }

    function formatDate(date:string){
        return new Date(date).toLocaleDateString("en-CA") + " 00:00:00";
    }

    async function exportReport() {
        try {
            const currentDate = new Date().toLocaleDateString("pt-BR")

            let outputFilename = `Relatorio-IdWall  ${currentDate.replaceAll("/","-")}.csv`;

            const response: any = await api.post(
                `/reports/idwall-acessos-csv`,
                {
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate),
                },
                {responseType: "blob"}
            );

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
            setLoadingExportar(false);
        } catch (error: any) {
            toast.error("Erro ao exportar arquivo");
            setLoadingExportar(false);
        }
    }

    return (
        <Container>
            <Header setSearch={setSearch} search={search} />
            <Actions>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                >
                    <div className="data-container">
                        <div>
                            <div>
                                <img src={calendario} alt="" /> <p>INÍCIO</p>
                            </div>
                            <MobileDatePicker
                                showToolbar={false}
                                componentsProps={{
                                    actionBar: {
                                        actions: [],
                                    },
                                }}
                                closeOnSelect
                                className="date-picker-start"
                                inputFormat="dd/MM/yyyy"
                                value={startDate}
                                onChange={(newValue) =>
                                    newValue && handleStartDate(newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="DD/MM/AAAA"
                                        error={false}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <div>
                                <img src={calendario} alt="" /> <p>FIM</p>
                            </div>
                            <MobileDatePicker
                                showToolbar={false}
                                componentsProps={{
                                    actionBar: {
                                        actions: [],
                                    },
                                }}
                                closeOnSelect
                                className="date-picker-start"
                                inputFormat="dd/MM/yyyy"
                                value={endDate}
                                onChange={(newValue) =>
                                    newValue && handleEndDate(newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="DD/MM/AAAA"
                                        error={false}
                                    />
                                )}
                            />
                        </div>

                        <button
                            disabled={startDate === "" || endDate === ""}
                            onClick={handleFetch}
                        >
                            <span className="lupa">
                                <img src={lupa} alt="" />
                            </span>
                        </button>
                    </div>
                </LocalizationProvider>
                <Button
                    disabled={!filtredData || loading}
                    className="export"
                    onClick={exportReport}
                    isLoading={loadingExportar}
                    spiner="BallTriangle"
                    spinerColor="secondary"
                >
                    <img src={download} alt="" />
                    <span>EXPORTAR</span>
                </Button>
            </Actions>

            <div className="container-table">
                {loading ? (
                    <div className="container-loading">
                        <Loader height={100} width={100} />
                    </div>
                ) : (
                    <>
                        <Table
                            rows={
                                dados.length > 0 ? [...dados, ...totais()] : []
                            }
                            columns={columns}
                            nameCSV="relatório ID Wall"
                        />
                    </>
                )}
            </div>
        </Container>
    );
}
