import { useContext, createContext, useState} from "react";
import { api } from "../../services";
import { toast } from "react-toastify";

export type Item ={
    tipo: string
    total: number
    valorUnitario: number
    valorTotal: number
    investimento: string
}


interface Data {
    data: {
        totalGeral: string,
		totalUnidades: string,
		dataTable: Item[]
    }
}

interface useRelatorioProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseRelatorio {
    fetch(startDate:string, endDate:string): Promise<Data | undefined>;
    response: Data;
    loading: boolean;
}

const UseRelatorioContext = createContext<UseRelatorio>({} as UseRelatorio);

export function RelatorioProviderIdWall(props: useRelatorioProviderProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<Data | {}>({});

    async function fetch(startDate:string, endDate:string) {
        if(!startDate || !endDate){
            toast.error("Data inicial e final são obrigatórias")
            return;
        }
        
        try {
            setLoading(true);
            const { data } = await api.post<Data>(
                `/reports/idwall-acessos`,
                {
                    startDate: startDate,
                    endDate: endDate,
                }
            );
            setResponse(data);
            setLoading(false);
            return data;
        } catch (error:any) {
            setResponse({ data: {} });
            setLoading(false);
            toast.error(error.response.data.message);
        }
    }


    return (
        <UseRelatorioContext.Provider
            value={{
                fetch,
                response: response as Data,
                loading,
            }}
        >
            {children}
        </UseRelatorioContext.Provider>
    );
}

export function useRelatorioIdWall() {
    const context = useContext(UseRelatorioContext);
    return context;
}